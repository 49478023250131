import React from "react";
import CONSTANTS from "../../../../utils/constants";

export default ({
  index,
  type = CONSTANTS.ACTIVITY_TYPES.INTERVENTION,
  intervention,
  onClickKnowMore,
  onClickQuoteButton,
}) => {
  const {
    label,
    title,
    titleMobile,
    imgSrc,
    imgAlt,
    imgTitle,
    imgSubtitle,
    durationMobile,
    price,
  } = intervention;
  return (
    <div
      className={
        "section-main-interventions__body--mobile__content " +
        "section-main-interventions__body--mobile__content--" +
        type.toLocaleLowerCase()
      }
      onClick={(e) => {
        e.preventDefault();
        onClickKnowMore(e, index, type);
      }}
    >
      <h4
        className="section-main-interventions__body--mobile__content__title"
        dangerouslySetInnerHTML={{
          __html: titleMobile || title,
        }}
      />

      <div className="section-main-interventions__body--mobile__content__img--wrapper">
        <img
          src={imgSrc || "/img/plombier-toulouse/water-heater_150x.png"}
          alt={imgAlt || "Chaudière"}
          width="80px"
        />
      </div>

      <h4 className="section-main-interventions__body--mobile__content__body__title">
        {imgTitle}
      </h4>
      <div className="section-main-interventions__body--mobile__content__body__subtitle">
        {imgSubtitle}
      </div>
      <div className="section-main-interventions__body--mobile__content__body__details__wrapper">
        {type === CONSTANTS.ACTIVITY_TYPES.INTERVENTION && (
          <div className="section-main-interventions__body--mobile__content__body__details">
            <div className="section-main-interventions__body--mobile__content__body__details_item">
              <img
                src="/img/plombier-toulouse/duration_40x.png"
                alt="Etoile"
                width="15px"
                className="section-main-interventions__body--mobile__content__body__details_item_img"
              />
              {"  "}
              Durée : <span className="font-droid">{durationMobile}</span>
            </div>
            <div className="section-main-interventions__body--mobile__content__body__details_item">
              <img
                src="/img/plombier-toulouse/piggy-bank_40x.png"
                alt="Etoile"
                width="15px"
                className="section-main-interventions__body--mobile__content__body__details_item_img"
              />
              {"  "}
              Tarif : <span className="font-droid">{price}</span>
            </div>
          </div>
        )}
        {type === CONSTANTS.ACTIVITY_TYPES.SUPPLY && (
          <div className="section-main-interventions__body--mobile__content__body__details">
            <button
              onClick={(e) => {
                e.preventDefault();
                onClickKnowMore(e, index, type);
              }}
              className="section-supply__body--mobile__content__body__details_item"
            >
              <img
                src="/img/plombier-toulouse/enjeu_40x.png"
                alt="Etoile"
                width="20px"
                className="section-main-interventions__body--mobile__content__body__details_item_img"
              />{" "}
              <span className="section-supply__body--mobile__content__body__details_item__text">
                Enjeux
              </span>{" "}
              <img
                src="/img/plombier-toulouse/enjeu_40x.png"
                alt="Etoile"
                width="20px"
                className="hidden"
              />
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                onClickKnowMore(e, index, type);
              }}
              className="section-supply__body--mobile__content__body__details_item"
            >
              <img
                src="/img/plombier-toulouse/steps_40x.png"
                alt="Etoile"
                width="20px"
                className="section-main-interventions__body--mobile__content__body__details_item_img"
              />
              {"  "}
              <span className="section-supply__body--mobile__content__body__details_item__text">
                Etapes
              </span>
              <img
                src="/img/plombier-toulouse/steps_40x.png"
                alt="Etoile"
                width="20px"
                className="hidden"
              />
            </button>
          </div>
        )}
        <div className="center m-t-1">
          <div className="section-main-interventions__body--mobile__know-more">
            {type === CONSTANTS.ACTIVITY_TYPES.INTERVENTION && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onClickKnowMore(e, index);
                }}
                className="section-main-interventions__body--mobile__know-more__anchor"
              >
                En savoir plus
              </a>
            )}
            {type === CONSTANTS.ACTIVITY_TYPES.SUPPLY && (
              <a
                href="#"
                data-label={type + "_" + label}
                onClick={onClickQuoteButton}
                className="section-main-interventions__body--mobile__know-more__anchor"
              >
                Demander un devis
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
