import React from "react";

export default ({ member = {} }) => {
  const {
    name,
    jobTitle,
    role,
    skills,
    pros,
    responsabilities,
    imgSrc,
    imgAlt,
  } = member;
  return (
    <div className="slider__card ">
      <div className="slider__card__illustration">
        <img
          src={imgSrc}
          alt={imgAlt}
          width="300px"
          className="rounded slider__card__illustration__img"
        />
      </div>
      <div className="slider__card__body">
        <h4 className="slider__card__body__name">{name}</h4>
        <h5 className="slider__card__body__job-title">{jobTitle}</h5>
        {skills && (
          <div>
            <h4 className="slider__card__body__subtitle">Qualifications</h4>
            <p
              className="slider__card__body__text"
              dangerouslySetInnerHTML={{ __html: skills }}
            />
          </div>
        )}
        {pros && (
          <div>
            <h4 className="slider__card__body__subtitle">Points Forts</h4>
            <p
              className="slider__card__body__text"
              dangerouslySetInnerHTML={{ __html: pros }}
            />
          </div>
        )}
        {role && (
          <div>
            <h4 className="slider__card__body__subtitle">Rôle</h4>
            <p
              className="slider__card__body__text"
              dangerouslySetInnerHTML={{ __html: role }}
            />
          </div>
        )}
        {responsabilities && (
          <div>
            <h4 className="slider__card__body__subtitle">Responsabilités</h4>
            <p
              className="slider__card__body__text"
              dangerouslySetInnerHTML={{ __html: responsabilities }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
