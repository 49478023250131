import React from "react";
import CONSTANTS from "../../../../utils/constants";

export default ({
  type = CONSTANTS.ACTIVITY_TYPES.INTERVENTION,
  intervention,
  onClickCloseBtn,
  onClickQuoteButton,
}) => {
  const {
    label,
    title,
    titleMobile,
    title1,
    text1,
    title2,
    title2Mobile,
    text2,
    steps2,
    title3,
    text3,
    steps3,
    imgSrc,
    imgAlt,
    imgTitle,
    imgSubtitle,
    durationMobile,
    price,
  } = intervention;
  return (
    <div className="section-activity__body--mobile__modal__container">
      <button
        onClick={onClickCloseBtn}
        className="section-activity__body--mobile__modal__close-btn"
      >
        X
      </button>
      <h3
        className="section-activity__body--mobile__modal__title"
        dangerouslySetInnerHTML={{
          __html: titleMobile || title,
        }}
      />
      <div className="section-activity__body--mobile__modal__body">
        <div className="section-activity__body--mobile__modal__img">
          <img
            src={imgSrc || "/img/plombier-toulouse/water-heater_150x.png"}
            alt={imgAlt || "Chaudière"}
            width="80px"
          />
        </div>
        <h4 className="section-main-interventions__body__content__body__title">
          {title1}
        </h4>
        <p
          className="section-main-interventions__body__content__body__content"
          dangerouslySetInnerHTML={{ __html: text1 }}
        />
        <h4 className="section-main-interventions__body__content__body__title">
          {title2Mobile || title2}
        </h4>
        {type === CONSTANTS.ACTIVITY_TYPES.INTERVENTION && (
          <p
            className="section-main-interventions__body__content__body__content"
            dangerouslySetInnerHTML={{ __html: text2 }}
          />
        )}
        {type === CONSTANTS.ACTIVITY_TYPES.SUPPLY && (
          <div>
            <div className="section-supply__body__content__body__content">
              <ul className="section-supply__body__content__body__content__list">
                {steps2.map((step, index) => {
                  return (
                    <li
                      key={index}
                      className="section-supply__body__content__body__content__list__item"
                    >
                      <span className="section-supply__body__content__body__content__list__item__number">
                        {index + 1}.
                      </span>{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: step,
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
            <h4 className="section-supply__body__content__body__title">
              {title3}
            </h4>
            <div className="section-supply__body__content__body__content">
              {text3}
              <ul className="section-supply__body__content__body__content__list-quote">
                {steps3.map((step, index) => {
                  return <li key={index}>{step}</li>;
                })}
              </ul>
            </div>
          </div>
        )}
        {type === CONSTANTS.ACTIVITY_TYPES.INTERVENTION && (
          <div className="section-activity__body--mobile__modal__details">
            <div className="section-main-interventions__body--mobile__content__body">
              <h4 className="section-main-interventions__body--mobile__content__body__title">
                {imgTitle}
              </h4>
              <div className="section-main-interventions__body--mobile__content__body__subtitle">
                {imgSubtitle}
              </div>
              <div className="section-activity__body--mobile__modal__details_items">
                <div className="section-activity__body--mobile__modal__details_item">
                  <img
                    src="/img/plombier-toulouse/duration_40x.png"
                    alt="Etoile"
                    width="15px"
                    className="section-activity__body--mobile__modal__details_item_img"
                  />
                  {"  "}
                  Durée : <span className="font-droid">{durationMobile}</span>
                </div>
                <div className="section-activity__body--mobile__modal__details_item">
                  <img
                    src="/img/plombier-toulouse/piggy-bank_40x.png"
                    alt="Etoile"
                    width="15px"
                    className="section-activity__body--mobile__modal__details_item_img"
                  />
                  {"  "}
                  Tarif : <span className="font-droid">{price}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="section-activity__body--mobile__modal__buttons">
        <button
          onClick={onClickCloseBtn}
          className="section-activity__body--mobile__modal__buttons__close-btn"
        >
          Fermer
        </button>
        <button
          data-label={type + "_" + label}
          onClick={onClickQuoteButton}
          className="section-activity__body--mobile__modal__buttons__quote-btn"
        >
          {type === CONSTANTS.ACTIVITY_TYPES.SUPPLY
            ? "Demander un devis"
            : "Demander un RDV"}
        </button>
      </div>
    </div>
  );
};
