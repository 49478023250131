import React from "react";
import Index from "../components/pages/plombier-toulouse";
import Layout from "../layouts/Layout";
import CONSTANTS from "../utils/constants";

export default () => {
  return (
    <Layout
      reference={CONSTANTS.PAGE_REFERENCES.PLOMBIER_TOULOUSE}
      metadata={{
        title: "Plombier Toulouse - RDV Rapide & Tarifs Clairs - Selfcity.fr",
        description: `Déplacement gratuit pour le devis. Tarification juste à partir de 85 € TTC. Qualité garantie. Venez voir les avis clients sur notre site. Tél : ${CONSTANTS.PHONE_NUMBERS["4483"].default}`,
      }}
    >
      <Index />
    </Layout>
  );
};
