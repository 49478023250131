import React from "react";

export default ({ visible = false, supply, onClickQuoteButton }) => {
  const {
    label,
    title1,
    text1,
    title2,
    steps2,
    title3,
    text3,
    steps3,
    tagline,
  } = supply;
  return (
    <div
      key={label}
      className={
        "section-supply__body__content " +
        (visible ? "" : "section-supply__body__content--hidden")
      }
    >
      <div className="section-supply__body__content__body">
        <h4 className="section-supply__body__content__body__title">{title1}</h4>
        <p
          className="section-supply__body__content__body__content"
          dangerouslySetInnerHTML={{
            __html: text1,
          }}
        />
        <h4 className="section-supply__body__content__body__title">{title2}</h4>
        <div className="section-supply__body__content__body__content">
          <ul className="section-supply__body__content__body__content__list">
            {steps2.map((step, index) => {
              return (
                <li
                  key={index}
                  className="section-supply__body__content__body__content__list__item"
                >
                  <span className="section-supply__body__content__body__content__list__item__number">
                    {index + 1}.
                  </span>{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: step,
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <h4 className="section-supply__body__content__body__title">{title3}</h4>
        <div className="section-supply__body__content__body__content">
          {text3}
          <ul className="section-supply__body__content__body__content__list-quote">
            {steps3.map((step, index) => {
              return <li key={index}>{step}</li>;
            })}
          </ul>
        </div>

        <div className="section-supply__body__content__body__footer">
          <div className="section-supply__body__content__body__footer__text">
            {tagline}
          </div>
          <div>
            <button
              className="section-supply__body__content__body__footer__button"
              data-label={"SUPPLY_" + label}
              onClick={onClickQuoteButton}
            >
              Demander un devis
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
