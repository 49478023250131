import URLParse from "url-parse";

export default class URLQueryHelper {
  constructor(document) {
    this.location = document.location;
    this.locationURL = URLParse(this.location, true);
  }
  get(key) {
    return this.locationURL.query[key];
  }

  getQuery() {
    return this.locationURL.query;
  }
}
