import React from "react";

import AppointmentBlock from "./components/AppointmentBlock";
import MainInterventionsBody from "./components/MainInterventionsBody";
import ActivityBodyMobile from "./components/ActivityBodyMobile";
import SupplyBody from "./components/SupplyBody";
import TeamCard from "./components/TeamCard";

import Slider from "../../Slider";
import ReviewCard from "../../Slider/ReviewCard";

import ContactFormModal from "../../elements/ContactFormModal";
import Map from "../../elements/Map";
import FormButton from "../../elements/FormButton";
import RatingsAnchor from "../../elements/RatingsAnchor";

import { forfaitsList } from "../../../utils/constants";

import interventions from "./data/interventions";
import supplies from "./data/supplies";
import professionals from "./data/professionals";
import teamMembers from "./data/teamMembers";
import reviews from "../../../assets/ratings/plombier-toulouse";
import pressMedias from "../../../assets/press";

import CONSTANTS from "../../../utils/constants";

import "../../../styles/index-2020-04.css";
import "../../../styles/activities.css";
import "../../../styles/activity-modal.css";

export default ({
  phoneNumber,
  showModal,
  showModalActivity,
  modalContent,
  modalLabel,
  activeMainInterventionIndex,
  activeSupplyIndex,
  onOpenContactFormModal,
  onCloseContactFormModal,
  onClickMainInterventionsItem,
  onClickSupplyItem,
  onOpenMainInterventionModal,
}) => {
  const reviewsCards = reviews.map((review, i) => {
    return <ReviewCard key={i} review={review} />;
  });
  const teamMembersCards = teamMembers.map((member, i) => {
    return <TeamCard key={i} member={member} />;
  });

  const pressAnchors = pressMedias.map((press) => {
    const { id, href, src, alt } = press;
    return (
      <a key={id} href={href} target="no_blank">
        <img className="press-logo" src={src} alt={alt} />
      </a>
    );
  });

  return (
    <main className="style-2020-04 page-plombier-toulouse">
      {showModal || showModalActivity ? (
        <ContactFormModal
          onClose={onCloseContactFormModal}
          title="Demande de RDV"
          modalContent={modalContent}
          label={modalLabel}
        />
      ) : null}

      <section className="home-section  section-intro">
        <div className="flex flex-align-center flex-justify-space-between">
          <div className="section-content flex-50">
            <h1 className="section-title ">
              Dépannage en Plomberie &amp; Chauffage à{" "}
              <span className="dark-green">Toulouse</span>
            </h1>

            <p className="section-body">
              Nous employons les meilleurs plombiers-chauffagistes pour un
              service qualitatif, réactif et abordable.
              <br />
              Intervention complète dès 85 € TTC.
            </p>
            <div className="awards flex flex-align-end">
              <span className="">
                Entreprise&nbsp;
                <br className="hide-big" />
                récompensée :
              </span>
              <span>
                <img
                  src="/img/partners/logo_ihg.png"
                  alt="Initiative Haute-Garonne"
                  width="95px"
                  className="hide-sm"
                />
                <img
                  src="/img/partners/logo_reseau_entreprendre_x80.png"
                  alt="Réseau Entreprendre"
                  height="55px"
                />
                <img
                  src="/img/partners/logo_agipi_finaliste.png"
                  alt="AGIPI"
                  height="55px"
                  className="awards-logo-agipi hide-big"
                />
              </span>
            </div>
            <div className="flex flex-align-center section-intro__ratings">
              <span className="">
                Avis clients (
                <RatingsAnchor text="voir" />) :
              </span>
              <span>
                <span className="section-intro__ratings__note">4.9 </span>
                <span className="section-intro__ratings__stars">
                  <img src="/img/icons/star.png" alt="Etoile" width="15px" />
                  <img src="/img/icons/star.png" alt="Etoile" width="15px" />
                  <img src="/img/icons/star.png" alt="Etoile" width="15px" />
                  <img src="/img/icons/star.png" alt="Etoile" width="15px" />
                  <img src="/img/icons/star.png" alt="Etoile" width="15px" />
                </span>
              </span>
              <br className="hide-big" />
            </div>
            <AppointmentBlock
              title="Demande en ligne"
              phoneNumber={phoneNumber.default}
              breakLine={false}
              options={{
                alternativeText: "Tél :",
                phoneNumberFontFamily: "DROID_SANS",
                label: "intro",
              }}
              onOpenContactFormModal={onOpenContactFormModal}
            />
          </div>
          <div className="section-illustration">
            <img
              src="/img/plombier-toulouse/home-heater_400x.png"
              alt="Maison avec plombier"
              width="400px"
            />
          </div>
        </div>
      </section>

      <section className="home-section flex section-best-service">
        <div className="section-illustration flex-55">
          <img
            id="team-img"
            className="hide-big"
            src="/img/team2_768x.jpg"
            alt="Equipe de Selfcity"
            width="400px"
          />
        </div>
        <div className="section-content bg-grey-1 ">
          <h2 className="section-title">
            Un dépannage
            <span className="dark-green normal"> rapide </span>
            et
            <span className="dark-green normal"> serein</span>.
          </h2>
          <div>
            <ul className="section-best-service__body__list">
              <li className="section-best-service__body__list__item">
                <img
                  src="/img/plombier-toulouse/medal_80x.png"
                  alt="Médaille"
                  width="40px"
                  className="section-best-service__body__list__item_img"
                />
                <div>
                  Tous nos plombiers à Toulouse sont{" "}
                  <span className="dark-green normal">diplômés </span>
                  et <span className="dark-green normal"> expérimentés</span>.
                </div>
              </li>
              <li className="section-best-service__body__list__item">
                <img
                  src="/img/plombier-toulouse/watch_adjusted_80x.png"
                  alt="Montre"
                  width="40px"
                  className="section-best-service__body__list__item_img"
                />
                <div>
                  Nous intervenons rapidement et
                  <span className="dark-green normal">
                    {" "}
                    respectons les horaires.
                  </span>
                </div>
                .
              </li>
              <li className="section-best-service__body__list__item">
                <img
                  src="/img/plombier-toulouse/trust_80x.png"
                  alt="Confiance"
                  width="40px"
                  className="section-best-service__body__list__item_img"
                />
                <div>
                  Nos tarifs sont abordables (
                  <a className="italic" href="#pricing">
                    voir
                  </a>
                  ) et{" "}
                  <span className="dark-green normal">
                    toujours connus d'avance
                  </span>
                  .
                </div>
              </li>
            </ul>
          </div>
          <AppointmentBlock
            title="Demande en ligne"
            phoneNumber={phoneNumber.default}
            options={{
              noText: true,
              phoneNumberFontFamily: "DROID_SANS",
              label: "best",
              classes: "bg-grey-1",
            }}
            onOpenContactFormModal={onOpenContactFormModal}
          />
        </div>
      </section>

      <section className="home-section center section-tagline">
        <h2 className="section-title hide-sm">
          Votre satisfaction est
          <span className="dark-green bold">&nbsp;notre priorité</span>.
        </h2>
        <h2 className="section-title hide-big dark-green bold">
          La satisfaction client est <br />{" "}
          <span className="dark-green bold">notre priorité</span>.
        </h2>
        <div>
          <Slider cards={reviewsCards} />
          <div className="m-t-1 hide-sm section-tagline__see-all">
            <RatingsAnchor text="Voir tous les avis" className="dark-green" />
          </div>
        </div>
        <AppointmentBlock
          title="Demande en ligne"
          phoneNumber={phoneNumber.default}
          onOpenContactFormModal={onOpenContactFormModal}
          options={{
            phoneNumberFontFamily: "DROID_SANS",
            label: "tagline",
          }}
        />
      </section>

      <section className="home-section section-press">
        <div className="flex flex-column flex-center">
          <h3 className="section-title">Ils parlent de nous</h3>
          <div className="press-logos flex flex-justify-space-evenly">
            {pressAnchors}
          </div>
        </div>
      </section>

      <section id="pricing" className="home-section section-pricing">
        <h3 className="section-title center">
          Nos tarifs sont transparents
          <br /> et
          <span className="green"> toujours connus d’avance</span>.
        </h3>

        <div className="section-body flex flex-justify-space-evenly ">
          <div className="panel-pricing flex-45">
            <h5 className="center panel-title">Interventions forfaitisées</h5>
            <p className="hide-sm">
              Pour les interventions de dépannage dont le périmètre est
              clairement défini, nous appliquons les forfaits suivants :
            </p>
            <ul className="">
              {forfaitsList.map(({ name, price }, idx) => (
                <li key={idx}>
                  <span>- {name}</span>
                  <span>
                    <span className="dark-green">{price}€&nbsp;</span>
                    <span>TTC</span>
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="panel-pricing panel-pricing-quote flex-45">
            <h5 className="center panel-title">Travaux sur devis</h5>
            <p>
              Après un RDV gratuit pour l’évaluation des travaux, nous vous
              faisons parvenir un devis dont le montant, (hors achat de
              matériel), est calculé comme suit :
            </p>
            <ul className="">
              <li>
                <span>- Forfait de déplacement -------</span>
                <span>30 € TTC</span>
              </li>
              <li>
                <span>- 1 heure de main d'oeuvre -----</span>
                <span>60 € TTC</span>
              </li>
              <li>
                <span>- Forfait de petite fourniture ----</span>
                <span>5 € TTC</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="center">
          <AppointmentBlock
            title="Demande en ligne"
            phoneNumber={phoneNumber.default}
            onOpenContactFormModal={onOpenContactFormModal}
            options={{
              phoneNumberFontFamily: "DROID_SANS",
              label: "pricing",
            }}
          />
        </div>
      </section>

      <section className="home-section section-main-interventions">
        <h3 className="section-title center section-main-interventions__title">
          Nos principales interventions de dépannage
        </h3>
        <div className="section-main-interventions__body--mobile hide-big">
          {interventions.map((intervention, index) => {
            return (
              <ActivityBodyMobile
                key={index}
                index={index}
                type={CONSTANTS.ACTIVITY_TYPES.INTERVENTION}
                intervention={intervention}
                onClickKnowMore={onOpenMainInterventionModal}
              />
            );
          })}
        </div>

        <div className="section-body section-main-interventions__body">
          <aside className="section-main-interventions__body__aside">
            <ul className="section-main-interventions__body__aside__list">
              {interventions.map((intervention, index) => {
                return (
                  <li
                    key={index}
                    className={
                      "section-main-interventions__body__aside__list__item " +
                      (activeMainInterventionIndex === index
                        ? "section-main-interventions__body__aside__list__item--active"
                        : "")
                    }
                  >
                    <a
                      href="#"
                      data-key={index}
                      onClick={onClickMainInterventionsItem}
                      className="section-main-interventions__body__aside__list__item__a"
                    >
                      {intervention.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </aside>

          {interventions.map((intervention, index) => {
            return (
              <MainInterventionsBody
                key={index}
                visible={activeMainInterventionIndex === index ? true : false}
                intervention={intervention}
              />
            );
          })}
        </div>
        <div className="center">
          <AppointmentBlock
            title="Demande en ligne"
            phoneNumber={phoneNumber.default}
            onOpenContactFormModal={onOpenContactFormModal}
            options={{
              phoneNumberFontFamily: "DROID_SANS",
              label: "interventions",
            }}
          />
        </div>
      </section>

      <section className="home-section section-supply">
        <h3 className="section-title center section-supply__title">
          Fourniture et pose d'équipements sanitaires et thermiques
        </h3>

        <div className="section-supply__body--mobile hide-big">
          {supplies.map((supply, index) => {
            return (
              <ActivityBodyMobile
                key={index}
                index={index}
                type={CONSTANTS.ACTIVITY_TYPES.SUPPLY}
                intervention={supply}
                onClickKnowMore={onOpenMainInterventionModal}
                onClickQuoteButton={onOpenContactFormModal}
              />
            );
          })}
        </div>

        <div className="section-supply__body">
          {supplies.map((supply, index) => {
            return (
              <SupplyBody
                key={index}
                visible={activeSupplyIndex === index}
                supply={supply}
                onClickQuoteButton={onOpenContactFormModal}
              />
            );
          })}

          <aside className="section-supply__body__aside">
            <ul className="section-supply__body__aside__list">
              {supplies.map((supply, index) => {
                return (
                  <li
                    key={index}
                    className={
                      "section-supply__body__aside__list__item " +
                      (activeSupplyIndex === index
                        ? "section-supply__body__aside__list__item--active"
                        : "")
                    }
                  >
                    <a
                      href="#"
                      data-key={index}
                      onClick={onClickSupplyItem}
                      className="section-supply__body__aside__list__item__a"
                      dangerouslySetInnerHTML={{
                        __html: supply.title,
                      }}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="center hide-sm section-supply__body__aside__cta">
              <AppointmentBlock
                title="Demande en ligne"
                phoneNumber={phoneNumber.default}
                onOpenContactFormModal={onOpenContactFormModal}
                options={{
                  phoneNumberFontFamily: "DROID_SANS",
                  label: "supply",
                }}
              />
            </div>
          </aside>
        </div>
        <div className="center hide-big section-supply__body__aside__cta">
          <AppointmentBlock
            title="Demande en ligne"
            phoneNumber={phoneNumber.default}
            onOpenContactFormModal={onOpenContactFormModal}
            options={{
              phoneNumberFontFamily: "DROID_SANS",
              label: "supply",
            }}
          />
        </div>
      </section>

      <section id="team" className="home-section section-team">
        <div className="section-team__wrapper">
          <h3 className="section-title center">L'équipe Selfcity à Toulouse</h3>
        </div>
        <Slider cards={teamMembersCards} />

        <div className="center section-team__cta">
          <AppointmentBlock
            title="Demande en ligne"
            phoneNumber={phoneNumber.default}
            onOpenContactFormModal={onOpenContactFormModal}
            options={{
              phoneNumberFontFamily: "DROID_SANS",
              label: "team",
            }}
          />
        </div>
      </section>

      <section
        id="professionals"
        className="home-section section-professionals"
      >
        <div className="section-professionals__wrapper">
          <h3 className="section-title">
            Les professionnels nous font confiance
          </h3>
          <div className="section-body">
            <p className="section-body__content">
              Selfcity intervient pour les particuliers autant qu’auprès de
              professionnels. Nous travaillons quotidiennement avec plusieurs
              acteurs de l’immobilier qui ont choisi Selfcity pour notre
              réactivité et{" "}
              <span className="bold dark-green">
                la qualité de notre service client
              </span>
              .
            </p>
          </div>
          <div className="section-illustration">
            <ul className="section-illustration__list">
              {professionals.map((professional) => {
                const { label, src, alt } = professional;
                return (
                  <li key={label} className="section-illustration__item">
                    <img
                      src={src}
                      alt={alt}
                      className="section-illustration__item__img"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="center section-professionals__cta">
          <AppointmentBlock
            title="Demande en ligne"
            phoneNumber={phoneNumber.default}
            onOpenContactFormModal={onOpenContactFormModal}
            options={{
              phoneNumberFontFamily: "DROID_SANS",
              label: "professionals",
            }}
          />
        </div>
      </section>

      <section className="home-section  flex flex-center section-green">
        <div className="bg-green white section-content">
          <h3 className="section-title">
            Un logement sain dans
            <br /> une ville saine
          </h3>
          <p>
            Pour contribuer à une ville dépolluée, nos plombiers circulent en
            véhicules électriques.
          </p>
          <p>
            Pour aller au bout de notre démarche, nous rechargeons nos véhicules
            par de l’électricité verte, issue des énergies{" "}
            <span className="bold">100% renouvelables </span>et
            <span className="bold"> 100% locales </span>.
          </p>
          <div className="block-form-button appointment-block">
            <FormButton
              text="Demande en ligne"
              onClick={onOpenContactFormModal}
              reverse={true}
              label="green"
            />
          </div>
        </div>
        <div className="section-illustration flex-55">
          <img
            className="hide-big"
            src="/img/index/green-city.jpg"
            alt="Ville saine"
            width="400px"
          />
        </div>
      </section>

      <section className="home-section center section-contact">
        <h2 className="section-title">
          Un souci de plomberie <br className="hide-big" /> ou de chauffage ?
        </h2>
        <div className="section-body">
          <p className="m-0">Appelez-nous au :</p>
          <p className="m-0 text-2xl font-droid section-contact__phone-number">
            {phoneNumber.default}
          </p>
        </div>
        <p className="section-body-2">Ou faites votre demande en ligne :</p>
        <AppointmentBlock
          title="Demande en ligne"
          options={{
            noText: true,
            label: "contact",
          }}
          onOpenContactFormModal={onOpenContactFormModal}
        />
      </section>

      <section className="home-section flex flex-justify-center section-contact-infos">
        <div className=" section-map hide-sm">
          <Map />
        </div>
        <div className="section-content">
          <h3 className="section-title">Selfcity Inc.</h3>
          <div className="section-body">
            <p>
              2 avenue de Lombez <br />
              31300 TOULOUSE
            </p>
            <p>
              {phoneNumber.default}
              <br />
              contact@selfcity.fr
            </p>
            <p className="hide-sm">
              SAS au capital de 35 000 €
              <br /> RCS de Toulouse : <br />
              84860177900012
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};
