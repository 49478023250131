export default [
  {
    label: "chaudiere",
    title: "Pose d’une chaudière",
    title1: "Enjeux de la pose",
    imgSrc: "/img/plombier-toulouse/chaudiere_150x.png",
    imgAlt: "Chaudière",
    text1: `Une chaudière est un éléments critique dont l’installation doit respecter des normes précises, notamment de sécurité. Seul un plombier chauffagiste professionnel certifié disposant des bons diplômes est habilité à poser et mettre en route une chaudière.`,
    title2: "Etapes de pose d'une chaudière à gaz",
    title2Mobile: "Principales étapes",
    steps2: [
      "<span class='semi-bold'>Raccordement hydraulique :</span> le chauffagiste pose les différents accessoires nécessaires au raccordement de la chaudière au circuit de chauffage ;",
      "<span class='semi-bold'>Raccordement électrique :</span> le professionnel branche la chaudière au circuit électrique pour son alimentation ;",
      "<span class='semi-bold'>Raccordement au gaz :</span> raccordement à la conduite de gaz pour l’alimentation de la chaudière ou raccordements correspondants pour les chaudières au fioul ou bois ;",
      "<span class='semi-bold'>Raccordement à l’eau chaude et froide :</span> dans le cas ou la chaudière est aussi utilisée pour chauffer l’eau à usage sanitaire ;",
      "<span class='semi-bold'>Raccordement des vidanges :</span> robinet permettant le nettoyage du circuit de chauffage ;",
      "<span class='semi-bold'>Raccordement au conduit d’évacuation :</span> permettant le rejet des produits de combustion (sous forme de fumée) à l’extérieur du logement ;",
      "<span class='semi-bold'>Mise en eau :</span> réglages du niveau d’eau et de la pression pour un fonctionnement optimal de la chaudière ;",
      "<span class='semi-bold'>Mise en service :</span> le chauffagiste met en route la chaudière installée aux normes de sécurité pour laquelle il vous fournira un certificat de conformité.",
    ],
    title3: "Devis",
    text3: "Le montant du devis de pose va dépendre des facteurs suivants : ",
    steps3: [
      "Le type de chaudière à poser et son coût ",
      "L’accessibilité et le poids de l’appareil (main d’oeuvre requise)",
      "Éventuels raccordements à créer ou à modifier",
    ],
    tagline: "Une chaudière à poser ou à remplacer à Toulouse ?",
  },
  {
    label: "chauffe-eau",
    title: "Pose d’un chauffe-eau",
    imgSrc: "/img/plombier-toulouse/chauffe-eau_150x.png",
    imgAlt: "Chauffe-eau",
    title1: "Enjeux de la pose",
    text1: `L’installation d’un chauffe-eau est une tâche technique, nécessitant connaissance des normes, capacité à créer et modifier des raccords, capacité à trouver des solutions pour s’adapter à l’espace disponible et à l’installation présente, ainsi qu’un effort physique important. `,
    title2: "Etapes de pose d'un chauffe-eau",
    title2Mobile: "Principales étapes",

    steps2: [
      "<span class='semi-bold'>Mise en sécurité électrique :</span> le chauffagiste procède à une mise en sécurité de l’ancien appareil en coupant l’alimentation grâce à un kit bloquant le redémarrage ;",
      "<span class='semi-bold'>Vidange de l’ancien appareil :</span> permet de retirer toute l’eau accumulée au sein du chauffe-eau via le siphon afin de préparer à sa dépose ;",
      "<span class='semi-bold'>Dépose de l’ancien chauffe-eau :</span> démontage de tous les raccordements de l’appareil (mitigeur, sortie d’eau, vase d’expansion, réducteur de pression) et dépose du ballon ;",
      "<span class='semi-bold'>Installation du nouveau ballon :</span> le type d’installation (trépied ou accrochage au mur) dépend de la taille du chauffe-eau ainsi que de l’espace disponible et de la qualité du mur ;",
      "<span class='semi-bold'>Raccordement d’eau :</span> raccordement aux arrivées d’eau via flexibles ;",
      "<span class='semi-bold'>Contrôle de l’étanchéité et mise en route :</span> permet de prévenir l’occurrence d’une fuite en vérifiant que le circuit est étanche avant la mise sous tension ;",
      "<span class='semi-bold'>Réglages et configurations :</span> le chauffagiste procède au paramétrage de la température et du fonctionnement en fonction de vos habitudes et besoins ;",
      "<span class='semi-bold'>Reprise de l’ancien appareil :</span> après installation, le chauffagiste nettoie le lieu de l’intervention et vous débarrasse de l’ancien ballon.",
    ],
    title3: "Devis",
    text3: "Le montant du devis de pose va dépendre des facteurs suivants : ",
    steps3: [
      "La taille et le coût du chauffe-eau à poser ",
      "L’accessibilité et le poids de l’appareil",
      "Éventuels raccordements à créer ou à modifier",
    ],
    tagline: "Un chauffe-eau à poser ou à remplacer à Toulouse ?",
  },
  {
    label: "douche",
    title: "Pose d’une douche",
    imgSrc: "/img/plombier-toulouse/douche2_150x.png",
    imgAlt: "Douche",
    title1: "Enjeux de la pose",
    text1: `La manière de poser une douche va dépendre du type de receveur et du système d’évacuation existant qui doit respecter une pente de 1cm à 3cm par mètre. Le bac à douche peut-être simplement posé, surélevé sur un socle ou encastré, inséré directement dans le sol.`,
    title2: "Etapes de pose d'une douche à l'italienne",
    title2Mobile: "Principales étapes",
    steps2: [
      "<span class='semi-bold'>Choix de l’emplacement :</span> il est recommandé de tracer au feutre l’emplacement du bac et de la bonde afin de s’assurer de la bonne superposition de l’installation ;",
      "<span class='semi-bold'>Contrôle du niveau :</span> permet de s’assurer de l’horizontalité parfaite de l’emplacement et un éventuel ajustement des pieds du receveur pour équilibrer ;",
      "<span class='semi-bold'>Installation de la bonde :</span> après démontage, le plombier installe des différents éléments puis raccorde du flexible d’évacuation à la sortie horizontale de la bonde ;",
      "<span class='semi-bold'>Installation du bac à douche :</span> en le fixant à l’aide d’un ciment colle ou de plâtre ;",
      "<span class='semi-bold'>Étanchéisation du receveur :</span> par application de mastic autour du trou de la bonde et la réalisation d’un joint en silicone entre les murs et le receveur pour éviter les infiltrations d’eau ;",
      "<span class='semi-bold'>Installation de la paroi :</span> en commençant par la pose la pose et la fixation du profilage, puis l’insertion de la paroi avant la fixation de celle-ci ;",
      "<span class='semi-bold'>Consolidation de la paroi :</span> par la fixation de la tringle (percer, cheviller, visser) puis la pose du sabot de blocage à coller sur le carrelage ;",
      "<span class='semi-bold'>Étanchéisation de la paroi :</span> par le dépôt d’un cordon de mastic silicone sur le pourtour de celle-ci, à sa liaison avec le mur et le sol ;",
      "<span class='semi-bold'>Installation de la colonne de douche :</span> étape finale pour une douche prête à l’emploi.",
    ],
    title3: "Devis",
    text3: "Le montant du devis de pose va dépendre des facteurs suivants : ",
    steps3: [
      "Le coût des équipements choisis ",
      "Le dépôt ou non de l’ancienne douche",
      "La quantité de travaux de plomberie nécessaires",
    ],
    tagline: "Une douche à poser ou à remplacer à Toulouse ?",
  },
  {
    label: "wc",
    title: "Pose d’un WC",
    titleMobile: "Pose d’un <br/>WC",
    imgSrc: "/img/plombier-toulouse/WC_150x.png",
    imgAlt: "WC",
    title1: "Enjeux de la pose",
    text1:
      "La complexité d’installation d’un WC dépend beaucoup de la différence entre le nouveau WC et l’ancien, notamment de l’<span class='bold'>évacuation</span> pouvant être verticale vers le sol (CA en arrière et S en dessous,) ou horizontale en H. Un modèle différent engendre des travaux sur le bâti.",
    title2: "Etapes de pose d'un WC",
    title2Mobile: "Principales étapes",
    steps2: [
      "<span class='semi-bold'>Choix de l’emplacement :</span> positionnement sur le sol par rapport à l’évacuation et identification des trous à percer au sol ;",
      "<span class='semi-bold'>Perçage du sol :</span> permet de préparer la fixation de la cuvette. Le profondeur, largeur et l’angle des trous va dépendre du type de sol ainsi que de la forme des pieds de cuvette ;",
      "<span class='semi-bold'>Pose et raccord :</span> placement de la cuvette et enfoncement de la sortie de la cuvette dans le manchon d’évacuation présent ;",
      "<span class='semi-bold'>Vérification d’horizontalité :</span> avec éventuel ajustement de cette dernière à l’aide des patins de caoutchouc à placer sous le pied ou avec du mastic injecté ;",
      "<span class='semi-bold'>Fixation de l’ensemble :</span> vissage des vis dans les chevilles précédemment percées en prenant soin de ne pas fendre la porcelaine ;",
      "<span class='semi-bold'>Montage du réservoir :</span> positionnement du réservoir sur le WC avec raccord des deux boulons et placement des rondelles d’étanchéité ;",
      "<span class='semi-bold'>Raccordement à l’arrivée d’eau :</span> en vissant l’embout du réservoir au robinet de coupure ;",
      "<span class='semi-bold'>Jointoyage :</span> réalisation d’un cordon de joint silicone tout autour du pied de la cuvette ;",
      "<span class='semi-bold'>Fixation de l’abattant :</span> en prenant soit de bloquer les vis sans trop les serrer pour éviter de fendre la porcelaine.",
    ],
    title3: "Devis",
    text3: "Le montant du devis de pose va dépendre des facteurs suivants : ",
    steps3: [
      "Le coût des équipements choisis ",
      "Le dépôt ou non de l’ancienne douche",
      "La quantité de travaux de plomberie nécessaires",
    ],
    tagline: "Un WC à poser ou à remplacer à Toulouse ?",
  },
  {
    label: "baignoire",
    title: "Pose d’une baignoire",
    imgSrc: "/img/plombier-toulouse/baignoire_150x.png",
    imgAlt: "Baignoire",
    title1: "Enjeux de la pose",
    text1:
      "Une baignoire bien posée est bien fixe, parfaitement horizontale et avec un système d’écoulement parfaitement étanche. Les baignoires peuvent être de plusieurs types (formes, tailles, matériaux, habillages, etc) et le montage de leurs différents éléments peut varier.",
    title2: "Etapes de pose d'une baignoire classique",
    title2Mobile: "Principales étapes",
    steps2: [
      "<span class='semi-bold'>Installation du mitigeur :</span> sur les arrivées d’eau chaudes et froides ;",
      "<span class='semi-bold'>Fixation les pieds :</span> en les assemblant à la baignoire ;",
      "<span class='semi-bold'>Réglage d’horizontalité :</span> avec ajustement de la hauteur des pieds et vérification de l’horizontalité à l’aide d’un niveau à bulles ;",
      "<span class='semi-bold'>Marquage du tasseau :</span> avec l’utilisation d’un crayon à bois sur le mur en suivant la ligne de contact entre les bords de la baignoire et le mur ;",
      "<span class='semi-bold'>Pose des tasseaux de maintien :</span> permet d’éviter que la baignoire ne bouge pendant l’utilisation et grâce à une fixation sous le bassin en suivant les marques ;",
      "<span class='semi-bold'>Assemblage du système d’écoulement :</span> avec installation de la bonde, le plombier visse le siphon puis raccorde le flexible reliant la bonde au trop-plein ;",
      "<span class='semi-bold'>Raccord de l’évacuation :</span> avec fixation du flexible d’évacuation à l’aide d’un joint en silicone, raccordement du flexible au tuyau d’évacuation et fixation du flexible au siphon ;",
      "<span class='semi-bold'>Pose de la baignoire :</span> après test de fonctionnement, le plombier vérifie la bonne étanchéité de l’ensemble de l’installation ;",
      "<span class='semi-bold'>Pose du tablier maintien et finitions :</span> après vissage des pieds de maintien, fixation des tasseaux de maintien et pose du tablier, il ne reste plus qu’à appliquer un joint de silicone le long des murs.",
    ],
    title3: "Devis",
    text3: "Le montant du devis de pose va dépendre des facteurs suivants : ",
    steps3: [
      "L’éventuelle dépose d’une baignoire existante",
      "Les modifications à apporter aux arrivées et évacuations d’eau",
      "Le type de baignoire et son coût",
    ],
    tagline: "Une baignoire à poser ou à remplacer à Toulouse ?",
  },
  {
    label: "radiateur",
    title: "Pose d’un radiateur",
    imgSrc: "/img/plombier-toulouse/radiateur_150x.png",
    imgAlt: "Radiateur",
    title1: "Enjeux de la pose",
    text1:
      "L’enjeu de la pose d’un radiateur dépend fortement du type d’installation. Tandis qu’un radiateur électrique ne requiert qu’une alimentation en électricité, la pose d’un radiateur de chauffage central est une opération de plomberie le raccordant au circuit hydraulique du chauffage.",
    title2: "Etapes de pose d'un radiateur de chauffage central",
    title2Mobile: "Principales étapes",
    steps2: [
      "<span class='semi-bold'>Préparation de l’installation :</span> en commençant par le traçage à l’aide d’un niveau pour un radiateur parfaitement horizontal, puis le vissage au mur des consoles de fixation ;",
      "<span class='semi-bold'>Pose du radiateur :</span> par une ou 2 personnes selon le poids du radiateur, sur les consoles précédemment fixées ;",
      "<span class='semi-bold'>Vérification et inclinaison :</span> après vérification de l’horizontalité grâce à un niveau à bulle, inclinaison maîtrisée vers le robinet de purge, dans le sens de l’écoulement ;",
      "<span class='semi-bold'>Vissage du robinet d’alimentation :</span> avec installation d’un joint et enroulage du Teflon pour éliminer les éventuels risques de fuite ;",
      "<span class='semi-bold'>Installation du purgeur :</span> à l’opposé du robinet d’alimentation ;",
      "<span class='semi-bold'>Pose du raccord d’évacuation :</span> dans la partie basse du radiateur, relié en circuit “retour” de l’installation de chauffage central ;",
      "<span class='semi-bold'>Vissage du bouchon de vidange :</span> sur le côté du radiateur, en opposition du raccord d’évacuation ;",
      "<span class='semi-bold'>Mise en place des tuyaux d’alimentation et d’évacuation :</span> à la fin de l’opération afin d’avoir la latitude pour positionner correctement les tés soudés et raccorder confortablement le radiateur du circuit hydraulique de chauffage central.",
    ],
    title3: "Devis",
    text3: "Le montant du devis de pose va dépendre des facteurs suivants : ",
    steps3: [
      "L’éventuelle dépose d’un radiateur existant",
      "Le nombre de radiateurs à poser",
      "La quantité de modifications à apporter au circuit pour le raccordement",
    ],
    tagline: "Un radiateur à poser ou à remplacer à Toulouse ?",
  },
  {
    label: "lavabo",
    title: "Pose d’un lavabo",
    imgSrc: "/img/plombier-toulouse/lavabo_150x.png",
    imgAlt: "Lavabo",
    title1: "Enjeux de la pose",
    text1:
      "L’installation d’un lavabo requiert minutie et précision. Sa fixation doit être solide et son raccord aux arrivées d’eau et à l’évacuation doit être fait convenablement pour éviter toute fuite. Un lavabo peut être suspendu, sur  ou être de type lavabo vasque à poser sur un plan de travail.",
    title2: "Etapes de pose d'un lavabo suspendu",
    title2Mobile: "Principales étapes",
    steps2: [
      "<span class='semi-bold'>Vérification de l’installation existante :</span> notamment la présence des deux arrivées d’eau en cuivre ou PER avec leurs raccords mâles respectifs ;",
      "<span class='semi-bold'>Fixation les raccords intermédiaires :</span> sur les arrivées d’eau en veillant à étanchéiser le raccord avec du Téflon ou de la filasse ;",
      "<span class='semi-bold'>Pose des robinets d’équerre :</span> qui rendent le système compatible avec les rallonges d’alimentation qui seront installées verticalement ;",
      "<span class='semi-bold'>Positionnement des tiges de fixation :</span> après repérage minutieux des trous à percer grâce au gabarit du lavabo ou du tapis d’insonorisation phonique ;",
      "<span class='semi-bold'>Pose du lavabo :</span> sur les tiges précédemment fixées avec mise en place des rondelles et des écrous, serrage puis découpe du tapis d’insonorisation ;",
      "<span class='semi-bold'>Vissage de la bonde :</span> avec étanchéisation grâce aux joints ;",
      "<span class='semi-bold'>Raccordement de la bonde :</span> à la main pour commencer et permettre la mesure de l’écart entre la sortie du siphon et l’évacuation au niveau du mur ;",
      "<span class='semi-bold'>Finalisation de l’évacuation :</span> ajustement et montage du tube d’évacuation, pose des joints, serrage de l’ensemble et vérification de l’étanchéité ;",
      "<span class='semi-bold'>Pose du joint en silicone :</span> entre le mur et le lavabo pour étanchéiser ;",
    ],
    title3: "Devis",
    text3: "Le montant du devis de pose va dépendre des facteurs suivants : ",
    steps3: [
      "L’éventuelle dépose de l’ancien lavabo",
      "Les modifications à apporter aux arrivées et évacuations d’eau",
      "Le type de lavabo et son coût",
    ],
    tagline: "Un lavabo à poser ou à remplacer à Toulouse ?",
  },
  {
    label: "electromenager",
    title: "Pose d’électro<wbr>ménager",
    imgSrc: "/img/plombier-toulouse/machine-a-laver_150x.png",
    imgAlt: "Machine à laver",
    title1: "Enjeux de la pose",
    text1:
      "Un lave-linge ou le lave-vaisselle ont besoin d’être raccordés à une alimentation et une évacuation d’eau. L’enjeu de l’installation vient de la présence ou non de robinets à l’endroit précis de l’appareil et d’où, souvent, des modifications de plomberie sont nécessaires pour les raccorder.",
    title2: "Etapes de pose d'un lave-linge",
    title2Mobile: "Principales étapes",
    steps2: [
      "<span class='semi-bold'>Choix de l’emplacement :</span> l’endroit idéal minimisant les nuisances sonores tout en étant proche d’une prise électrique, d’une arrivée d’eau et d’un tuyau d’évacuation ;",
      "<span class='semi-bold'>Mise en place d’une surélévation :</span> étape recommandée pour limiter les nuisances sonores, le support pouvant être livré avec la machine ou artisanal ;",
      "<span class='semi-bold'>Débridage du tambour :</span> par retrait du système de verrouillage afin que le processus de lavage puisse se dérouler normalement ;",
      "<span class='semi-bold'>Raccord de l’alimentation hydraulique :</span> raccord de l'extrémité d’arrivée d’eau au robinet après avoir éventuellement posé un robinet autoperceur sur la canalisation d’alimentation ;",
      "<span class='semi-bold'>Raccord du conduit d’évacuation :</span> le raccord pouvant se faire sur l’évacuation prévue à cet effet ou le cas échéant par adaptation d’un siphon en PVC ;",
      "<span class='semi-bold'>Raccord de l’alimentation électrique :</span> en respectant les normes de sécurité exigeant une prise de terre à une distance minimale d’un mètre par rapport au point d’eau ;",
      "<span class='semi-bold'>Ajustement des pieds :</span> par dévissage des contre-écrous se trouvant sur les pieds jusqu’à ce que l’appareil soit parfaitement horizontal.",
    ],
    title3: "Devis",
    text3: "Le montant du devis de pose va dépendre des facteurs suivants : ",
    steps3: [
      "L’éventuelle dépose de l’ancienne machine",
      "L’accessibilité des différents circuits requis",
      "La quantité de travaux de plomberie nécessaires",
    ],
    tagline: "Une machine à laver ou un lave-vaisselle à poser ?",
  },
];
