export default [
  {
    label: "chaudiere",
    title: "Réparation de Chaudière",
    titleMobile: "Chaudière",
    title1: "Rôle et fonctionnement",
    text1:
      "Qu’elle fonctionne au gaz, au fioul, à l’électricité ou au bois, le principe d’une chaudière est de réchauffer de l’eau à très haute température avant de l’injecter dans un circuit fermé passant par l’ensemble des radiateurs de la maison. <br/><br/>Une chaudière est éventuellement raccordée à une alimentation en gaz, une évacuation (pour dégager les produits de la combustion), au circuit électrique et au circuit de chauffage de la maison.",
    title2: "Pannes fréquentes d'une chaudière",
    text2:
      "Une panne de chaudière se manifeste par un chauffage absent ou inefficace. Dans les cas les plus simples, la panne est due à l’encrassage de certaines pièces ou à un problème de configuration du gicleur ou du brûleur par exemple. Sinon, le problème peut aussi venir d’une pièce défectueuse que le plombier doit remplacer ou du circuit de chauffage qui peut être encrassé ou obstrué et qu’il faut désembouer.",
    imgSrc: "/img/plombier-toulouse/chaudiere_150x.png",
    imgAlt: "Chaudière",
    imgTitle: "Réparation simple",
    imgSubtitle: "(sans remplacement de pièce)",
    duration: "30min - 1h",
    durationMobile: "30 - 60m",
    price: "à partir de 60 € TTC",
    knowMoreLink: null,
  },

  {
    label: "chauffe-eau",
    title: "Réparation de Chauffe-eau",
    titleMobile: "Chauffe-eau",
    title1: "Rôle et fonctionnement",
    text1:
      "Le chauffe-eau est un réservoir d’eau préchauffée grâce à un système de résistance électrique et de thermostat qui vous permet d’avoir instantanément accès à de l’eau chaude pour tous les robinets du logement. <br/><br/>Il est d’usage de configurer le chauffe-eau ou “cumulus” pour une mise sous tension automatique de la résistance aux heures creuses de consommation électrique afin de réduire sa facture électrique.",
    title2: "Pannes fréquentes d'un chauffe-eau",
    text2:
      "Une panne de chauffe-eau se manifeste le plus souvent par une absence d’eau chaude dans les robinet ou une fuite d’eau. Cela peut provenir d’un problème dans l’un des composants (thermostat ou résistance défectueux) ou, dans le cas d’un écoulement d’eau trop régulier au niveau du groupe de sécurité, d’une soupape défectueuse.",
    imgSrc: "/img/plombier-toulouse/chauffe-eau_150x.png",
    imgAlt: "Chauffe-eau",
    imgTitle: "Réparation simple",
    imgSubtitle: "(sans remplacement de pièce)",
    duration: "30min - 45min",
    durationMobile: "30 - 45m",
    price: "à partir de 60 € TTC",
    knowMoreLink: "/changement-cumulus-toulouse",
    knowMoreToolTip: "Changement de chauffe-eau à Toulouse",
  },
  {
    label: "recherche-fuite",
    title: "Recherche de fuite",
    titleMobile: "Fuite",
    title1: "Manifestations et causes d'une fuite",
    text1:
      "Une fuite d’eau cachée peut se manifester de différentes manières : l’apparition d’une tâche jaunâtre sur un mur avec odeur d’humidité, des gouttes d’eau depuis le plafond, la peinture qui perd sa couleur, une facture d’eau anormalement élevée, etc.<br/><br/> Les causes possibles sont aussi multiples, un trou dans un tuyau à cause du gel, usure d’un joint, mauvais serrage d’un robinet, installation mal conçue, etc.",
    title2: "Prise en charge par l’assurance",
    text2:
      "Identifier l’origine d’une fuite cachée demande des connaissances des installations de plomberie, du matériel et de la technicité pour éventuellement casser ou percer une paroi afin accéder aux canalisations endommagées. Un passage caméra peut être très utile pour s’assurer de la cause avant de casser. La plupart du temps la prestation est prise en charge par votre assurance habitation.",
    imgSrc: "/img/plombier-toulouse/fuite_recherche_150x.png",
    imgAlt: "Recherche de fuite",
    imgTitle: "Réparation simple",
    imgSubtitle: "(source cachée)",
    duration: "30min - 120min",
    durationMobile: "30 - 120m",
    price: "140 € TTC",
    knowMoreLink: "/recherche-fuite-toulouse",
    knowMoreToolTip: "Recherche de fuite à Toulouse",
  },
  {
    label: "debouchage",
    title: "Débouchage de canalisations",
    titleMobile: "Débouchage",
    title1: "Rôle et fonctionnement",
    text1:
      "Les tuyaux d’évacuation permettent d’éliminer les eaux usées en les acheminant vers les égouts. <br/><br/>Pour ce faire, l’eau usée va passer par plusieurs niveaux de canalisations, ceux des équipements initiaux (évier, lavabo, WC, douche, baignoire, lave-vaisselle, machine à laver, etc), les collecteurs, les descentes d’eaux ménagères, puis enfin les collecteurs principaux qui sont directement connectés aux égouts.",
    title2: "Techniques de débouchage",
    text2:
      "La technique de débouchage utilisée va dépendre de l’équipement concerné par l’engorgement, de la nature du bouchon et de son accessibilité. Pour opter pour la bonne stratégie, une inspection caméra peut s’avérer très utile afin de comprendre les raisons de l'obturation. Généralement, le plombier a recours dans l’ordre, au déboucheur à pompe, au furet puis à l’hydrocureur haute pression avant d’éventuellement recourir à des solutions spécialisées plus lourdes.",
    imgSrc: "/img/plombier-toulouse/debouchage_150x.png",
    imgAlt: "Débouchage",
    imgTitle: "Débouchage simple",
    imgSubtitle: "(avec pompe)",
    duration: "30min - 45min",
    durationMobile: "30 - 45m",
    price: "à partir de 60 € TTC",
    knowMoreLink: "/debouchage-toulouse",
    knowMoreToolTip: "Débouchage de canalisations à Toulouse",
  },
  {
    label: "entretien",
    title: "Entretien de chaudière",
    titleMobile: "Entretien",
    title1: "Rôle et fonctionnement",
    text1:
      "Avec l’utilisation, plusieurs composants d’une chaudière peuvent s’encrasser et perdre de leur efficacité à chauffer ainsi que leur efficacité énergétique. <br/><br/>Elle peut notamment présenter un risque car une mauvaise combustion dégage du monoxyde de carbone pouvant provoquer l’intoxication. En plus d’être une obligation légale, il est donc essentiel d’entretenir sa chaudière pour l’efficacité et la sécurité.",
    title2: "Principales tâches d'entretien",
    text2:
      "Le chauffagiste va d’abord contrôler tous les organes de sécurité, puis vérifier l'étanchéité gaz et eau, nettoyer le corps de chauffe, le brûleur et la veilleuse, vérifier le fonctionnement des brûleurs, le débit du gaz (à régler si nécessaire) avant de contrôler le taux de monoxyde de carbone. Enfin, le plombier vous conseillera sur la bonne utilisation de votre installation pour un chauffage efficace et une facture énergétique faible, en toute sécurité.",
    imgSrc: "/img/plombier-toulouse/chaudiere_150x.png",
    imgAlt: "Entretien chaudière",
    imgTitle: "Visite d'entretien",
    imgSubtitle: "(sur chaudière fonctionnelle)",
    duration: "45min - 1h",
    durationMobile: "45 - 60m",
    price: "60 € TTC",
    knowMoreLink: "/entretien-chaudiere-toulouse",
    knowMoreToolTip: "Entretien de chaudière à Toulouse",
  },
  {
    label: "wc",
    title: "Réparation de fuite WC",
    titleMobile: "Fuite WC",
    title1: "Rôle et fonctionnement",
    text1:
      "Le parcours de l’eau au sein d’un WC classique est simple : le robinet d’arrêt alimente le réservoir qui déverse ensuite dans la cuve (lorsqu’on tire la chasse d’eau), puis les eaux usées qui empruntent la pipe de sortie qui peut être horizontale ou verticale. <br/><br/> un WC suspendu, le parcours est le même mais l’ensemble des canalisations est encastré. Pour un WC suspendu, l’accès à la plomberie se fait alors grâce à une trappe d’accès.",
    title2: "Fuites fréquentes de WC",
    text2:
      "Il y a d’abord la fuite au niveau du robinet d’arrêt WC, qui peut être causée par un joint usé ou lorsque le robinet est resté inutilisé pendant une longue durée. Ensuite, il y a la fuite entre le réservoir et la cuve avec un filet d’eau qui coule en permanence et qui peut être causée par le joint d’étanchéité ou la chasse d’eau. Enfin, la fuite peut se situer au niveau de la pipe d’évacuation souvent à cause d’un joint d’évacuation défectueux.",
    imgSrc: "/img/plombier-toulouse/WC_150x.png",
    imgAlt: "Fuite WC",
    imgTitle: "Réparation simple",
    imgSubtitle: "(cas du robinet d'arrêt)",
    duration: "30min - 1h",
    durationMobile: "30 - 60m",
    price: "85 € TTC",
    knowMoreLink: "/probleme-chasse-d-eau-toulouse",
    knowMoreToolTip: "Réparation de chasse d'eau à Toulouse",
  },
  {
    label: "fuite-robinet",
    title: "Réparation de fuite robinet",
    titleMobile: "Robinet",
    title1: "Rôle et fonctionnement",
    text1:
      "Le robinet est généralement alimenté par deux flexibles, un pour l’eau chaude et un pour l’eau froide. <br/><br/>Le robinet en lui-même, qui peut être de plusieurs types (mitigeur standard, mélangeur ou mitigeur thermostatique) va contrôler le débit et la température de l’eau qui va s’écouler dans la vasque. En dessous de la vasque, le siphon permet de boucher hermétiquement la connexion entre votre évier/lavabo et les égouts.",
    title2: "Fuites fréquentes de robinet",
    text2:
      "La fuite peut survenir à tous les niveaux de l'installation de plomberie présentée ci-dessus. Elle peut provenir du robinet en lui-même, au niveau des poignées ou de l’embouchure, la solution peut être alors soit la refixation du robinet soit son remplacement. Elle peut aussi se manifester au niveau des flexibles, avec le même type de solutions. Enfin, si la fuite provient d’un siphon défectueux, il pourra être remplacé sur place par le plombier.",
    imgSrc: "/img/plombier-toulouse/fuite_robinet_150x.png",
    imgAlt: "Fuite robinet",
    imgTitle: "Réparation simple",
    imgSubtitle: "(Fixation et resserage)",
    duration: "30min - 1h",
    durationMobile: "30 - 60m",
    price: "60€ TTC",
    knowMoreLink: null,
  },
  {
    label: "chasse",
    title: "Réparation de chasse d’eau",
    titleMobile: "Chasse d'eau",
    title1: "Rôle et fonctionnement",
    text1:
      "La chasse d’eau est composée de plusieurs éléments, le réservoir, l’alimentation en eau et le système d’évacuation. Lorsque vous tirez la chasse, l’obturateur se soulève pour laisser couler l’eau puis reprend sa place bloquant ainsi l’écoulement. <br/><br/>Avec le niveau de l’eau qui baisse dans le réservoir, le flotteur baisse déclenchant le remplissage du réservoir en eau jusqu’à un niveau d’eau prédéfini qui coupe le robinet.",
    title2: "Pannes fréquentes de chasse d'eau",
    text2:
      "Parmi les problèmes fréquents : le mécanisme peut se bloquer, le remplissage du réservoir peut être trop lent ou l’écoulement dans la cuvette peut ne pas s’arrêter. Les causes peuvent être l’usure des joints, l’accumulation de calcaire au niveau du clapet ou du flotteur ou la cassure d’une pièce. De la cause va dépendre la solution, soit un démontage et nettoyage soit le remplacement de la pièce défectueuse.",
    imgSrc: "/img/plombier-toulouse/WC_150x.png",
    imgAlt: "Chasse d'eau",
    imgTitle: "Mécanisme défectueux",
    imgSubtitle: "(avec remplacement complet)",
    duration: "30min - 1h",
    durationMobile: "30 - 60m",
    price: "85 € TTC",
    knowMoreLink: "/probleme-chasse-d-eau-toulouse",
    knowMoreToolTip: "Réparation de chasse d'eau à Toulouse",
  },
];
