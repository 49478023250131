export default [
  {
    name: "Gérard Turino",
    jobTitle: "Plombier, Toulouse",
    skills: `<span class="semi-bold">Installateur thermique et sanitaire</span>,<br/> plombier à Toulouse depuis 2008`,
    pros: `30 années de métier dans le dépannage, Gérard est un plombier complet et polyvalent. <strong>Sanitaires</strong>, <strong>chauffe-eau</strong>, chaudière, <strong>recherche de fuite</strong>, l'exéprience de Gérard lui permet de résoudre un très large panel de situations.`,
    imgSrc: "/img/team-members/gerard.jpg",
    imgAlt: "Gérard Turino",
  },
  {
    name: "Loïc Destefani",
    jobTitle: "Plombier, Toulouse",
    skills: `<span class="semi-bold">Installateur thermique et sanitaire</span>,<br/> plombier à Toulouse depuis 2012`,
    // pros: `Professionnel du gaz, Loïc a une expertise sur les chaudières au gaz et au fioul de toutes tailles. Que ce soit pour de simples visites annuelles ou pour identifier des pannes cachées, Loïc fait preuve d'une efficacité remarquable sur les équipements thermiques.`,
    pros: `Professionnel du gaz, Loïc a une expertise sur les <strong>chaudières au gaz</strong> et au <strong>fioul</strong> de toutes tailles. Que ce soit pour de simples <strong>visites annuelles</strong> ou pour identifier des pannes cachées, Loïc fait preuve d'une <strong>efficacité</strong> remarquable sur les équipements thermiques.`,
    imgSrc: "/img/team-members/loic.jpg",
    imgAlt: "Loïc Destefani",
  },
  {
    name: "Billel Benali",
    jobTitle: "Plombier, Toulouse",
    skills: `<span class="semi-bold">Installateur thermique et sanitaire</span>,<br/> plombier à Toulouse depuis 2011`,
    pros: `Un solide expérience en plomberie et maçonnerie acquise sur plusieurs années de <strong>chantiers dans le neuf</strong>, Billel est un plombier polyvalent particulièrement pertinent sur les <strong>chantiers de rénovation</strong> et les raccordements complexes.`,
    imgSrc: "/img/team-members/billel.jpg",
    imgAlt: "Billel Benali",
  },
  {
    name: "Anthony Marty",
    jobTitle: "Plombier, Toulouse",
    skills: `<span class="semi-bold">Installateur des systèmes énergétiques et climatiques</span>,<br/> plombier à Toulouse depuis 2014`,
    pros: `<strong>Plombier polyvalent</strong>, Anthony a un savoir-faire éprouvé sur les <strong>chaudières</strong> comme sur les <strong>équipements sanitaires</strong>. Particulièrement adroit sur le travail du cuivre et des joints, Anthony brille aussi par sa <strong>serviabilité</strong> et sa bonne humeur.`,
    imgSrc: "/img/team-members/anthony.jpg",
    imgAlt: "Anthony Marty",
  },
  {
    name: "Sany Hallami",
    jobTitle: "Plombier, Toulouse",
    skills: `<span class="semi-bold">Installateur de climatiseurs et équipements de réfrigération</span>,<br/> plombier à Toulouse depuis 2018`,
    pros: `Spécialiste en réparation et <strong>maintenance des climatiseurs et des pompes à chaleur</strong>, Sany bénéficie en outre d'une solide base en <strong>installations sanitaires</strong> et chantiers de <strong>rénovation</strong>. Touche à tout, Sany est plombier et mécanicien.`,
    imgSrc: "/img/team-members/sany.jpg",
    imgAlt: "Sany Hallami",
  },
  {
    name: "Grégory Schnell",
    jobTitle: "Plombier, Toulouse",
    skills: `<span class="semi-bold">Installateur thermique et sanitaire,</span>,<br/> plombier à Toulouse depuis 2017`,
    pros: `Passionné de bricolage, Grégory s'est ré-orienté vers la plomberie après des études en marketing. Après 3 années d'expérience en chantiers, Grégory fait désormais partie de notre équipe de plombiers dépanneurs.`,
    imgSrc: "/img/team-members/gregory.jpg",
    imgAlt: "Grégory Schnell",
  },
  {
    name: "Olivier Assa",
    jobTitle: "Plombier en apprentissage, Toulouse",
    role: `Olivier a rejoint notre équipe en contrat d'apprentissage afin de monter en compétence en <strong>plomberie sanitaire</strong> et se spécialiser sur l'entretien des <strong>chaudières au gaz</strong>.`,
    imgSrc: "/img/team-members/olivier.jpg",
    imgAlt: "Olivier Assa",
  },
  {
    name: "Julian Aubry",
    jobTitle: "Magasinier, Toulouse",
    role: `Magasinier depuis plusieurs années dans des domaines variés, Julian renforce notre équipe sur le terrain en s'occupant de la logistique et des livraisons sur chantier.`,
    imgSrc: "/img/team-members/julian.jpg",
    imgAlt: "Julian Aubry",
  },
  {
    name: "Coralie Blanco",
    jobTitle: "Responsable support, Toulouse",
    role: `Management de l'équipe support pour le suivi des clients et des plannings des plombiers`,
    responsabilities: `Coralie gère le processus d'intervention, la relation client et coordonne l'opérationnel pour offrir aux clients un service réactif et fluide.`,
    imgSrc: "/img/team-members/coralie.jpg",
    imgAlt: "Coralie Blanco",
  },
  {
    name: "Céline Vatinet",
    jobTitle: "Chargée de support, Toulouse",
    role: `Relation client, Gestion de planning`,
    responsabilities: `Céline accompagne les clients et gère le planning des plombiers.`,
    imgSrc: "/img/team-members/celine.jpg",
    imgAlt: "Céline Vatinet",
  },
  {
    name: "Océane Sanson",
    jobTitle: "Chargée de support, Toulouse",
    role: `Relation client, Gestion de planning`,
    responsabilities: `Océane accompagne les clients et gère le planning des plombiers`,
    imgSrc: "/img/team-members/oceane.jpg",
    imgAlt: "Océane Sanson",
  },
];
