import React from "react";
import FormButton from "../../../elements/FormButton";

// Label is used for GA tracking: know which button has been clicked.
export default ({
  title,
  phoneNumber,
  breakLine = true,
  onOpenContactFormModal,
  options = { label: null, noText: false, classes: "" },
}) => {
  return (
    <div className="block-form-button appointment-block">
      {!options.noText && (
        <div className="appointment-block__body">
          {!options.alternativeText && (
            <p className="m-0">Appelez-nous au :&nbsp;</p>
          )}
          <p
            className={
              "m-0 appointment-block__phone-number " +
              (options.phoneNumberFontFamily === "DROID_SANS"
                ? "font-droid"
                : "")
            }
          >
            {options.alternativeText ? options.alternativeText + " " : ""}
            {phoneNumber}
          </p>
        </div>
      )}
      <FormButton
        text={title}
        classes={options.classes}
        onClick={onOpenContactFormModal}
        label={options.label}
      />
    </div>
  );
};
