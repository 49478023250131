import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from '../../../context'

import URLQueryHelper from '../../../utils/URLQueryHelper'

import GAEventHelper from '../../../utils/GAEventHelper'
import ActivityModalContainer from './components/ActivityModalContainer'

import interventions from './data/interventions'
import supplies from './data/supplies'

import View from './View'

import CONSTANTS from '../../../utils/constants'
import { openNewJotformTab } from '../../../utils/jotformLink'

export default () => {
	const [state] = useContext(AppContext)
	const { phoneNumber } = state

	const [showModal, setShowModal] = useState(false)
	const [showModalActivity, setShowModalActivity] = useState(false)
	const [currentModalContent, setCurrentModalContent] = useState(null)
	const [currentModalLabel, setCurrentModalLabel] = useState(null)

	const [activeInterventionIndex, setActiveInterventionIndex] = useState(0)
	const [activeSupplyIndex, setActiveSupplyIndex] = useState(0)

	const [currentIntervention, setCurrentIntervention] = useState(null)
	const [currentSupply, setCurrentSupply] = useState(null)

	const findIndexFromKey = ({ items, key = 'label', value }) => {
		let index = 0
		// Find index from key
		for (let i = 0; i < items.length; i++) {
			const item = items[i]
			if (item[key] === value) {
				index = i
				break
			}
		}
		return index
	}

	useEffect(() => {
		/**
		 * Get active intervention and supply from URL
		 */
		const query = new URLQueryHelper(document).getQuery()
		const selectedInterventionLabel = query.intervention
		const selectedSupplyLabel = query.pose

		const activeInterventionIndex = findIndexFromKey({
			items: interventions,
			value: selectedInterventionLabel,
		})
		setActiveInterventionIndex(activeInterventionIndex)

		const activeSupplyIndex = findIndexFromKey({
			items: supplies,
			value: selectedSupplyLabel,
		})
		setActiveSupplyIndex(activeSupplyIndex)
	}, [])

	const handleOpenContactFormModal = (e, metadata = {}) => {
		e.preventDefault()
		e.stopPropagation()

		let { label } = metadata
		if (!label && e && e.currentTarget) {
			label = e.currentTarget.dataset.label
		}

		GAEventHelper.triggerClickEvent({
			category: CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.DEMANDE_DEVIS,
			action: CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.OPEN,
			label: label,
			options: {
				window,
			},
		})

		openNewJotformTab('plombier_toulouse')

		// setShowModal(true)
		// setCurrentModalContent(null)
		// setCurrentModalLabel(label)
	}

	const handleCloseContactFormModal = () => {
		setShowModal(false)
		setCurrentModalLabel(null)

		// Check if modalActivity was opened
		// const { currentSupply, currentIntervention } = this.state;
		let activity = null
		let type = null

		if (currentIntervention !== null) {
			activity = currentIntervention
			type = CONSTANTS.ACTIVITY_TYPES.INTERVENTION
		} else if (currentSupply !== null) {
			activity = currentSupply
			type = CONSTANTS.ACTIVITY_TYPES.SUPPLY
		}

		if (activity !== null) {
			const { label } = activity
			const modalContent = (
				<ActivityModalContainer
					intervention={activity}
					type={type}
					onClickCloseBtn={handleCloseMainInterventionModal}
					onClickQuoteButton={handleOpenContactFormModal}
				/>
			)
			setShowModal(true)
			setCurrentModalContent(modalContent)
			setCurrentModalLabel(label)
		}
	}

	const handleClickMainInterventionsItem = (event) => {
		event.preventDefault()
		const { target } = event
		const index = parseInt(target.dataset.key)
		setActiveInterventionIndex(index)
	}

	const handleClickSupplyItem = (event) => {
		event.preventDefault()
		const { target } = event
		const index = parseInt(target.dataset.key)
		setActiveSupplyIndex(index)
	}

	const handleOpenMainInterventionModal = (
		event,
		key,
		type = CONSTANTS.ACTIVITY_TYPES.INTERVENTION,
	) => {
		event.preventDefault()
		const index = parseInt(key)
		let activity = null
		let label = null

		if (type === CONSTANTS.ACTIVITY_TYPES.INTERVENTION) {
			activity = interventions[index]
			label = activity.label
			setCurrentIntervention(activity)
		} else if (type === CONSTANTS.ACTIVITY_TYPES.SUPPLY) {
			activity = supplies[index]
			label = activity.label
			setCurrentSupply(activity)
		}

		const modalContent = (
			<ActivityModalContainer
				intervention={activity}
				type={type}
				onClickCloseBtn={handleCloseMainInterventionModal}
				onClickQuoteButton={handleOpenContactFormModal}
			/>
		)

		setShowModalActivity(true)
		setCurrentModalContent(modalContent)
		setCurrentModalLabel(label)
	}

	const handleCloseMainInterventionModal = (event) => {
		event.preventDefault()

		setShowModalActivity(false)
		setCurrentIntervention(null)
		setCurrentSupply(null)
		setCurrentModalContent(null)
		setCurrentModalLabel(null)
	}

	return (
		<View
			phoneNumber={phoneNumber}
			showModal={showModal}
			showModalActivity={showModalActivity}
			modalContent={currentModalContent}
			modalLabel={currentModalLabel}
			activeMainInterventionIndex={activeInterventionIndex}
			activeSupplyIndex={activeSupplyIndex}
			onOpenContactFormModal={handleOpenContactFormModal}
			onCloseContactFormModal={handleCloseContactFormModal}
			onClickMainInterventionsItem={handleClickMainInterventionsItem}
			onClickSupplyItem={handleClickSupplyItem}
			onOpenMainInterventionModal={handleOpenMainInterventionModal}
		/>
	)
}
