import React from "react";

export default ({ visible = false, intervention }) => {
  const {
    title1,
    text1,
    title2,
    text2,
    imgSrc,
    imgAlt,
    imgTitle,
    imgSubtitle,
    duration,
    price,
    knowMoreLink,
    knowMoreToolTip,
  } = intervention;
  return (
    <div
      className={
        "hide-sm section-main-interventions__body__content " +
        (visible ? "" : "section-main-interventions__body__content--hidden")
      }
    >
      <div className="section-main-interventions__body__content__row-1">
        <div className="section-main-interventions__body__content__body">
          <h4 className="section-main-interventions__body__content__body__title section-main-interventions__body__content__row-1">
            {title1}
          </h4>
          <p
            className="section-main-interventions__body__content__body__content"
            dangerouslySetInnerHTML={{ __html: text1 }}
          />
        </div>

        <div className="section-main-interventions__body__content__aside">
          <div className="section-main-interventions__body__content__aside__img--wrapper">
            <img
              src={imgSrc || "/img/plombier-toulouse/water-heater_150x.png"}
              alt={imgAlt || "Chaudière"}
              width="80px"
            />
          </div>

          <div className="section-main-interventions__body__content__body__2">
            <h4 className="section-main-interventions__body__content__aside__title">
              {imgTitle}
            </h4>
            <div className="section-main-interventions__body__content__aside__subtitle">
              {imgSubtitle}
            </div>
            <div className="section-main-interventions__body__content__aside__details">
              <div className="section-main-interventions__body__content__aside__details_item">
                <img
                  src="/img/plombier-toulouse/duration_40x.png"
                  alt="Etoile"
                  width="20px"
                  className="section-main-interventions__body__content__aside__details_item_img"
                />
                Durée : <span className="font-droid">{duration}</span>
              </div>
              <div className="section-main-interventions__body__content__aside__details_item">
                <img
                  src="/img/plombier-toulouse/piggy-bank_40x.png"
                  alt="Etoile"
                  width="20px"
                  className="section-main-interventions__body__content__aside__details_item_img"
                />
                Tarif : <span className="font-droid">{price}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-main-interventions__body__content__row-2">
        <h4 className="section-main-interventions__body__content__body__title">
          {title2}
        </h4>
        <p
          className="section-main-interventions__body__content__body__content"
          dangerouslySetInnerHTML={{ __html: text2 }}
        />
        {knowMoreLink && (
          <div className="section-main-interventions__body__know-more">
            <a
              href={knowMoreLink}
              className="section-main-interventions__body__know-more__anchor"
              title={knowMoreToolTip}
            >
              En savoir plus
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
