export default [
  {
    label: "servimmo",
    src: "/img/agencies/b_w_servimmo.png",
    alt: "Servimmo",
  },
  {
    label: "acim",
    src: "/img/agencies/b_w_ACIM.png",
    alt: "Acim Immobilier",
  },
  {
    label: "abcd",
    src: "/img/agencies/b_w_ABCD.png",
    alt: "ABCD Immobilier",
  },
  {
    label: "orpi",
    src: "/img/agencies/b_w_ORPI.png",
    alt: "ORPI",
  },
  {
    label: "a2j",
    src: "/img/agencies/b_w_A2J.png",
    alt: "A2J",
  },

  {
    label: "multigest",
    src: "/img/agencies/b_w_Multigest.png",
    alt: "Multigest",
  },
  {
    label: "cespi",
    src: "/img/agencies/b_w_Cespi.png",
    alt: "Cespi",
  },
  {
    label: "ad",
    src: "/img/agencies/b_w_AD-Immobilier.png",
    alt: "AD Immobilier",
  },
  {
    label: "equation",
    src: "/img/agencies/b_w_Equation.png",
    alt: "Equation Immobilier",
  },
  {
    label: "maquet",
    src: "/img/agencies/b_w_Maquet.png",
    alt: "Maquet Immobilier",
  },
];
